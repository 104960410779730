import './Stylesheets/Landing.css';
import leaf from './Media/leaf.png'
import video from './Media/c.mp4'
import transparentlogo from './Media/logo10.png'
import { useState, useEffect } from 'react';
export default function Landing() {
    const [showLogo, setShowLogo] = useState(false)
    
    useEffect(()=>{
        setShowLogo(true)
    },[])
    return (
        <div className="LandingContainer">
            <div  className='NameAndLeaf'>
                <img className={`logo ${showLogo? 'show':''}`} src={transparentlogo}/>
         
           
            </div>

            <div className='video-container'>
      <video  autoPlay loop muted>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
        </video> 
       <div class="overlay"></div> 
        </div>

          
       
          <div className="Center ButtonContainer">
         
           <a href='#Pricing'>   <div className="ViewPlansButton"> Get Started</div></a>
          </div>
        
 
        </div>
    );
}
