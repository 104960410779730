import About from "./About";
import Coaches from "./Coaches";
import Contact from "./Contact";
import FAQ from "./FAQ";
import Navbar from "./Navbar";
import Pricing from "./Pricing";
import Schedule from "./Schedule";
import Parallax from "./Parallax/Parallax.js";
import "./Stylesheets/App.css"
import Parallax2 from "./Parallax/Parallax2.js";
import Parallax3 from "./Parallax/Parallax3.js";
import Landing from "./Landing";
import Jocko from "./Components/Jocko.js";
import Footer from "./Footer";
import useScrollAnimation from "./Custom/useScrollAnimation.js";
import Login from "./Login.js";
import Purchase from "./Components/Purchase.js";
import { AppContextProvider, ApplicationContext } from './Custom/Context.js';
import Founders from "./Founders.js";

function App() {
  useScrollAnimation();
  return (
    <AppContextProvider >
    <div className="App">
      <Navbar />
   
      <Landing />
    
      <About />
  
      <Pricing />
   <Parallax3 />
 
      <Coaches />
 <Parallax2 />
      <Schedule />
      <Parallax />
      <FAQ />
      <Contact />
      <Footer />

 
    </div>
    </AppContextProvider>
  );
}

export default App;

