import './Stylesheets/Navbar.css'
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faTimes, faBars, faUser} from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import Login from './Login';
import logo from './Media/logo5.png'
import logobottom from './Media/logobottom.png'
import logofull from './Media/logo10.png'
import '@fortawesome/fontawesome-free/css/all.min.css';
 
 
 
export default function Navbar(){
    const [expand, setExpand] = useState(false);
    const [showMenu, setShowMenu] = useState(false)
    const [isLoginVisible, setLoginVisible] = useState(false);
 
    const changeExpand = () => {
        if (window.scrollY >= 400) {
            setExpand(true);
        } else {
            setExpand(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeExpand);
        return () => {
            window.removeEventListener('scroll', changeExpand);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 800) {
                setShowMenu(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
     }, []);

    const toggleMenu = () => {
        setShowMenu((prevState) => !prevState);
    };

    const toggleLogin = () => {
      
        setLoginVisible(!isLoginVisible); // Toggle the login modal
    };
    return (
        <div className='NavbarContainer'> 
        
        <div className={`LogoAndHamburger ${expand? 'expand':''}`} >
       <img className={`navbar-logo ${showMenu? 'opaque':''} ${expand? 'expand':''}`}  src={logo} />
      
        <div onClick={toggleMenu} className='HamburgerMenu'>
           {showMenu ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
        </div>             
        </div>
           <ul className={`${showMenu? 'show': ''} NavbarList`}>
              { expand?   <img id='logo-bottom' src={logobottom}/>: <div id='logo-full'>   <img  src={logofull} /> </div> }
          <a href='#About' >   <li onClick={()=>setShowMenu(false)}>About</li></a>
          <a href='#Coaches'>   <li onClick={()=>setShowMenu(false)}>Instructors</li></a>
          <a href='#Schedule'>   <li onClick={()=>setShowMenu(false)}>Schedule</li></a>
          <a href='#FAQ'>  <li onClick={()=>setShowMenu(false)}>F.A.Q.</li></a>
          <a href='#Contact'> <li onClick={()=>setShowMenu(false)}>Contact Us</li></a>
          <a className='LoginFlex'>   <li onClick={toggleLogin}>  <FontAwesomeIcon icon={faUser}/> <span  className='LoginText'>Log In </span> </li></a>
            <div className='NavbarButtons'>
             <a href='#Pricing'> <div onClick={()=>setShowMenu(false)} className="NavbarViewPlansButton"> Get Started</div></a>
            </div>
           </ul>
           {isLoginVisible && <Login toggleLogin={toggleLogin} />}

       
         
        </div>
    )
}