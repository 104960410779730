import '../Stylesheets/Jocko.css'

export default function Jocko() {
    return(
        <div className='Jocko animate'>
    <iframe 
  
    src="https://www.youtube.com/embed/EGNEc2Dppgc?" 
    title="YouTube video player" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
    allowfullscreen>
</iframe>
</div>)
}