import {useState, useContext, createContext } from 'react'
const AppContext=createContext()
export const ApplicationContext = () =>useContext(AppContext)

export const AppContextProvider = ({ children }) => {
    const [getStarted, setGetStarted] = useState(false)
    const handleClick = () => {
        setGetStarted(true); // Update the state when the button is clicked
    };
 
 
    return (
        <AppContext.Provider value={{ handleClick, getStarted }}>
            {children}
       </AppContext.Provider>
    )
}